import { animated, easings, useSpring } from "@react-spring/web";
import { Tabs, message } from "antd";
import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import DefaultStyle from "./defaultStyle";
import "./funtabs.css";
import Header from "./header";
import OnlyIconStyle from "./onlyIconStyle";
import PhoneStyle from "./phoneStyle";
import Settings from "./settings";

const $ = require("jquery");

const funtabsData = {
  model: "",
  widthNum: 260,
  heightNum: 100,
  radius: 6,
  gap: 18,
  cardStyle: "defaultCard",
  tabsActiveKey: 1671384254000,
  backgroundImage: "/wallpaper.jpg",
  content: [
    {
      label: "PC端",
      key: 1671384254000,
      content: [
        {
          label: "河南能源人力集团官网",
          link: "https://www.hnnyrl.com/",
          size: "11",
          icon: "/icons/nyrl.jpg",
          type: "link",
        },
        {
          label: "中原能才网",
          link: "http://zytalent.com/",
          size: "11",
          icon: "/icons/p-zync.png",
          type: "link",
        },
        {
          label: "一体化管理平台",
          link: "http://36.133.39.97:18080/login?inviteUrl=%2Fapplicationview%2Fcontent%2Fview%3Fappid%3Da77735c7-7cd9-1919-afc5-0b737d7d9838%26type%3Dview%26menuId%3D8ed41836-af6b-4cb3-af41-35d23c2b6779%25233",
          size: "11",
          icon: "/icons/ythpt.png",
          type: "link",
        },
        {
          label: "中原能才网管理后台",
          link: "http://cms.zytalent.com/",
          size: "11",
          icon: "/icons/p-zync.png",
          type: "link",
        },
        {
          label: "能才报名助手管理后台",
          link: "https://enroll.zytalent.com/kdfYscCTJo.php",
          size: "11",
          icon: "/icons/p-bmzs.png",
          type: "link",
        },
        {
          label: "能源人力HRO系统",
          link: "http://hnnyssc.ruirenyun.cn",
          size: "11",
          icon: "/icons/p-hro.png",
          type: "link",
        },
        {
          label: "能源人力CRM系统",
          link: "http://crm.hnnyrl.com",
          size: "11",
          icon: "/icons/p-crm.png",
          type: "link",
        },
        {
          label: "能源人力考试学习平台",
          link: "https://onlearning.zytalent.com/",
          size: "11",
          icon: "/icons/p-nct.png",
          type: "link",
        },
      ],
      value: 1671384254000,
    },
    {
      label: "移动端",
      key: 1671385421000,
      content: [
        {
          link: "https://www.photopea.com1/",
          label: "能源人才集团抖音号",
          size: "11",
          icon: "/code/dyh.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com2/",
          label: "能源人力公众号",
          size: "11",
          icon: "/code/nyrlgzh.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com3/",
          label: "中原能才公众号",
          size: "11",
          icon: "/code/zyncgzh.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com4/",
          label: "豫好工小程序",
          size: "11",
          icon: "/code/yhg.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com5/",
          label: "能才通（考试学习系统）",
          size: "11",
          icon: "/code/nct.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com6/",
          label: "能才报名助手",
          size: "11",
          icon: "/code/ncbmzs.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com7/",
          label: "中原能才微信小程序",
          size: "11",
          icon: "/code/zync.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com8/",
          label: "中原能才支付宝小程序",
          size: "11",
          icon: "/code/zync-ali.png",
          type: "link",
        },
        {
          link: "https://www.photopea.com8/",
          label: "中原能才抖音小程序",
          size: "11",
          icon: "/code/zync-douyin.png",
          type: "link",
        },
      ],
      value: 1671385421000,
    },
    {
      label: "实用工具",
      key: 1671380778000,
      content: [
        {
          label: "万能工具宝藏",
          link: "https://tool.browser.qq.com/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://aria.mereith.com/touchicon.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "二维码生成",
          link: "https://cli.im/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://app.wireflow.co/safari-pinned-tab.svg",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "在线简历制作工具",
          link: "http://cv.ftqq.com/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://fox.ftqq.com/bearcv/favicon.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "思维导图、流程图",
          link: "https://app.diagrams.net/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://app.diagrams.net/favicon.ico",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "在线视频下载解析",
          link: "https://www.parsevideo.com/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://pic.mereith.com/img/20210827232745.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "在线图片压缩",
          link: "https://www.picdiet.com/zh-cn",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://pic.mereith.com/img/%E5%8E%8B%E7%BC%A9%E6%9C%BAR.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "Pexel",
          link: "https://www.pexels.com/zh-cn/",
          size: "11",
          icon: "https://api.iowen.cn/favicon/www.pexels.com.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "朋友圈转发截图生成工具",
          link: "https://akarin.dev/WechatMomentScreenshot/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://res.wx.qq.com/a/wx_fed/assets/res/OTE0YTAw.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "短视频去水印",
          link: "https://screenlane.com/",
          size: "11",
          icon: "https://demo-tools.mereith.com/api/img?url=https://www.photopea.com/promo/icon512.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "在线PS",
          link: "https://www.photopea.com/",
          size: "11",
          icon: "https://api.iowen.cn/favicon/www.todaybing.com.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "图片工具",
          link: "https://imagestool.com/",
          size: "11",
          icon: "https://api.iowen.cn/favicon/imagestool.com.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "在线抠图",
          link: "https://www.remove.bg/zh",
          size: "11",
          icon: "https://api.iowen.cn/favicon/www.remove.bg.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "改图神器",
          link: "https://img.logosc.cn/",
          size: "11",
          icon: "https://api.iowen.cn/favicon/img.logosc.cn.png",
          type: "link",
          chosen: false,
          selected: false,
        },
        {
          label: "PDF文件在线处理工具",
          link: "https://www.ilovepdf.com/zh-cn",
          size: 11,
          icon: "https://api.iowen.cn/favicon/www.ilovepdf.com.png",
          type: "link",
          chosen: false,
          selected: false,
        },
      ],
      value: 1671380778000,
    },
    // {
    //   label: "常用",
    //   key: 0,
    //   content: [
    //     {
    //       label: "便捷记事本",
    //       type: "note",
    //       id: 1671810458244,
    //       chosen: false,
    //       selected: false,
    //     },
    //     {
    //       label: "快捷翻译器",
    //       type: "translatelite",
    //       id: 1671810458242,
    //       chosen: false,
    //       selected: false,
    //     },
    //     {
    //       label: "时间进度条",
    //       type: "timeProgress",
    //       id: 1671810449901,
    //       chosen: false,
    //       selected: false,
    //     },
    //     {
    //       label: "吾爱破解",
    //       link: "https://52pojie.cn",
    //       size: "11",
    //       icon: "/icons/pojie.svg",
    //       type: "link",
    //       backgroundColor: "#C90E3B",
    //     },
    //   ],
    //   value: 0,
    // },
  ],
};

const LinkList = () => {
  const localData = JSON.parse(window.localStorage.getItem("funtabs")); //获取本地存储的数据
  const localActiveKey = JSON.parse(window.localStorage.getItem("activeKey")); //获取本地存储的数据
  const modelData = window.localStorage.getItem("model"); //获取本地存储的模式
  //卡片当前激活的分类
  const [tabsActiveKey, setTabsActiveKey] = useState(() => {
    try {
      if (localActiveKey) {
        return localActiveKey;
      } else {
        return funtabsData.tabsActiveKey;
      }
    } catch (error) {
      return funtabsData.tabsActiveKey;
    }
  });
  //卡片展示的列表
  const [linkList, setLinkList] = useState(() => {
    try {
      if (localData) {
        return localData.newData.content.filter(
          (item) => item.key === tabsActiveKey
        )[0].content;
      } else {
        return funtabsData.content.filter(
          (item) => item.key === tabsActiveKey
        )[0].content;
      }
    } catch (error) {
      return funtabsData.content.filter((item) => item.key === tabsActiveKey)[0]
        .content;
    }
  });
  //当前激活的模式（简约或默认）
  const [model, setModel] = useState(() => {
    try {
      if (modelData) {
        return modelData;
      } else {
        return funtabsData.model;
      }
    } catch (error) {
      return funtabsData.model;
    }
  }); //简约和默认
  //定义卡片的宽度、高度、圆角、卡片样式、卡片间距大小
  const [widthNum, setWidthNum] = useState(() => {
    try {
      if (localData) {
        return localData.newData.widthNum;
      } else {
        return funtabsData.widthNum;
      }
    } catch (error) {
      return funtabsData.widthNum;
    }
  });
  const [heightNum, setHeightNum] = useState(() => {
    try {
      if (localData) {
        return localData.newData.heightNum;
      } else {
        return funtabsData.heightNum;
      }
    } catch (error) {
      return funtabsData.heightNum;
    }
  });
  const [radius, setRadius] = useState(() => {
    try {
      if (localData) {
        return localData.newData.radius;
      } else {
        return funtabsData.radius;
      }
    } catch (error) {
      return funtabsData.radius;
    }
  });
  const [cardStyle, setCardStyle] = useState(() => {
    try {
      if (localData) {
        return localData.newData.cardStyle;
      } else {
        return funtabsData.cardStyle;
      }
    } catch (error) {
      return funtabsData.cardStyle;
    }
  });
  const [gap, setGap] = useState(() => {
    try {
      if (localData) {
        return localData.newData.gap;
      } else {
        return funtabsData.gap;
      }
    } catch (error) {
      return funtabsData.gap;
    }
  });
  //卡片分类列表
  const [tabsItems, setTabsItems] = useState(() => {
    try {
      if (localData) {
        return localData.newData.content;
      } else {
        return funtabsData.content;
      }
    } catch (error) {
      return funtabsData.content;
    }
  });
  //其他文本
  const [edit, setEdit] = useState("none");
  const [drag, setDrag] = useState(true);
  const [editText, setEditText] = useState("编辑导航");
  const [color, setColor] = useState("");
  const [dropFilter, setDropFilter] = useState("");
  const [tabsVisibility, setTabsVisibility] = useState("");
  const backgroundImage = window.localStorage.getItem("backgroundImage");
  const [url, setUrl] = useState(() => {
    try {
      if (
        backgroundImage === "null" ||
        backgroundImage === "undefined" ||
        backgroundImage === null
      ) {
        return funtabsData.backgroundImage;
      } else {
        return `${backgroundImage}`;
      }
    } catch (error) {
      return funtabsData.backgroundImage;
    }
  });

  const [linkListAnimation, api] = useSpring(() => ({
    from: {
      y: 20,
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
    },
    delay: 200,
    config: {
      duration: 300,
      easing: easings.easeOutCubic,
    },
  }));

  const [gridWidth, setGridWidth] = useState("100%");

  //网格布局样式信息
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, ${widthNum}px`,
    justifyContent: "center",
    columnGap: gap + "px",
    rowGap: gap + "px",
    gridAutoFlow: "dense",
    gridAutoRows: heightNum + "px",
    maxWidth: "100%",
  };

  const gridStyle1 = {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, 160px`,
    justifyContent: "center",
    columnGap: 22 + "px",
    rowGap: 22 + "px",
    gridAutoFlow: "dense",
    gridAutoRows: "160px",
    maxWidth: "100%",
  };

  //判断标签页是否显示
  const tabsVis = () => {
    let a = tabsItems.length;
    if (a === 1) {
      setTabsVisibility("none");
    } else {
      setTabsVisibility("");
    }
  };

  useEffect(() => {
    const localData = JSON.parse(window.localStorage.getItem("funtabs")); //获取本地存储
    tabsVis();
    //当页面的分类key变化的时候显示对应的卡片列表
    setLinkList(() => {
      try {
        if (localData) {
          return localData.newData.content.filter(
            (item) => item.key === tabsActiveKey
          )[0].content;
        } else {
          return funtabsData.content.filter(
            (item) => item.key === tabsActiveKey
          )[0].content;
        }
      } catch (error) {
        return funtabsData.content.filter(
          (item) => item.key === tabsActiveKey
        )[0].content;
      }
    });
    changeGridWidth();
    // eslint-disable-next-line
  }, [tabsActiveKey]);

  function changeGridWidth() {
    setTimeout(() => {
      const classList = $("div[class^='grid-item']");
      var classNum = 0;
      for (let i = 0; i < classList.length; i++) {
        const classWidth = parseInt(classList[i].className.substr(-2, 1));
        classNum += classWidth;
      }
      setGridWidth(`${classNum * widthNum + gap * (classNum - 1)}`);
    }, 10);
  }

  //编辑
  function editFunction() {
    if (edit === "none") {
      setEdit("");
      setDrag(false);
      // message.warning("您正处于编辑模式,可拖动排列卡片～");
      setColor("rgb(0 0 0 / 50%)");
      setDropFilter("blur(5px)");
      setEditText("退出编辑");
      setSettingsAreaAnimation.start({
        from: {
          y: -20,
          opacity: 0,
        },
        to: {
          y: 0,
          opacity: 1,
        },
      });
    } else {
      setEdit("none");
      setDrag(true);
      setColor("");
      setDropFilter("");
      saveData();
      message.success("本地保存成功");
      setEditText("编辑导航");
    }
  }

  //保存数据到本地
  function saveData() {
    var newData; //本地存储数据是newData
    //如果本地数据存在，保存应针对当前本地存储的newData，否则数据应该是内置数据
    if (localData) {
      newData = localData.newData;
    } else {
      newData = funtabsData;
    }
    newData.content = tabsItems;
    newData.content.filter((item) => item.key === tabsActiveKey)[0].content =
      linkList;
    newData.gap = gap;
    newData.widthNum = widthNum;
    newData.heightNum = heightNum;
    newData.cardStyle = cardStyle;
    newData.radius = radius;
    //存储到本地
    window.localStorage.setItem("funtabs", JSON.stringify({ newData }));
  }

  function saveActiveKey(e) {
    window.localStorage.setItem("activeKey", e);
  }

  const [settingsAreaAnimation, setSettingsAreaAnimation] = useSpring(
    () => ({})
  );

  const howToShow = () => {
    if (tabsActiveKey != "1671385421000") {
      return (
        <ReactSortable
          id="sortable"
          key="sortable1"
          list={linkList}
          setList={(e) => {
            setLinkList(e);
          }}
          tag="div"
          style={{ ...gridStyle, width: `${gridWidth}px` }}
          disabled={drag}
          animation={500}
        >
          {linkList.map((item, index) => {
            return (
              <DefaultStyle
                key={item.link + item.type + item.id}
                id={index}
                edit={edit}
                item={item}
                linkList={linkList}
                setLinkList={setLinkList}
                radius={radius}
                widthNum={widthNum}
                heightNum={heightNum}
                tabsActiveKey={tabsActiveKey}
                cardStyle={cardStyle}
                changeGridWidth={changeGridWidth}
              />
            );
          })}
        </ReactSortable>
      );
    } else {
      return (
        <ReactSortable
          id="sortable"
          key="sortable3"
          list={linkList}
          setList={(e) => {
            setLinkList(e);
          }}
          tag="div"
          style={{ ...gridStyle1, width: "100%" }}
          disabled={drag}
          animation={500}
        >
          {linkList.map((item, index) => {
            return (
              <PhoneStyle
                key={item.link + item.type + item.id}
                id={index}
                edit={edit}
                item={item}
                linkList={linkList}
                setLinkList={setLinkList}
                radius={radius}
                widthNum="160"
                heightNum="160"
                tabsActiveKey={tabsActiveKey}
                cardStyle={cardStyle}
                changeGridWidth={changeGridWidth}
              />
            );
          })}
        </ReactSortable>
      );
    }
  };

  return (
    <>
      <Header
        model={model}
        editFunction={editFunction}
        editText={editText}
        drag={drag}
        setModel={setModel}
        url={url}
        setUrl={setUrl}
      />
      <div className="gridArea">
        <div key="showList" style={{ width: "100%", display: model }}>
          <Tabs
            items={tabsItems}
            activeKey={tabsActiveKey}
            centered
            tabBarStyle={{
              color: "#fff",
              fontWeight: "bold",
              marginTop: "-22px",
              display: tabsVisibility,
            }}
            onChange={(e) => {
              if (edit === "") {
                setTabsActiveKey(e);
                saveActiveKey(e);
                saveData();
              } else {
                setTabsActiveKey(e);
                saveActiveKey(e);
              }
              api.start({
                from: {
                  y: 20,
                  opacity: 0,
                },
                to: [
                  {
                    y: 19.9,
                    opacity: 0,
                  },
                  {
                    y: 0,
                    opacity: 1,
                  },
                ],
                config: {
                  duration: 180,
                  easing: easings.easeOutCubic,
                },
              });
            }}
          />
          <animated.div
            id="grid-div"
            style={{
              ...linkListAnimation,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {howToShow()}
          </animated.div>
        </div>
      </div>
      {/* eslint-disable-next-line */}
      <animated.div
        style={{
          backgroundColor: color,
          backdropFilter: dropFilter,
          position: "absolute",
          top: "0px",
          position: "fixed",
          zIndex: 20,
          ...settingsAreaAnimation,
        }}
      >
        <Settings
          model={model}
          widthNum={widthNum}
          setWidthNum={setWidthNum}
          heightNum={heightNum}
          setHeightNum={setHeightNum}
          linkList={linkList}
          setLinkList={setLinkList}
          edit={edit}
          editFunction={editFunction}
          radius={radius}
          setRadius={setRadius}
          cardStyle={cardStyle}
          setCardStyle={setCardStyle}
          gap={gap}
          setGap={setGap}
          tabsActiveKey={tabsActiveKey}
          setTabsActiveKey={setTabsActiveKey}
          funtabsData={funtabsData}
          tabsItems={tabsItems}
          setTabsItems={setTabsItems}
          setTabsVisibility={setTabsVisibility}
          localData={localData}
          url={url}
          setUrl={setUrl}
          api={api}
          changeGridWidth={changeGridWidth}
        />
      </animated.div>
    </>
  );
};

export { LinkList, funtabsData };
