import {
  DownloadOutlined,
  GithubFilled,
  InteractionFilled,
  LockFilled,
  MailFilled,
  QqOutlined,
  UploadOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import "./funtabs.css";
import "./index.css";
import { funtabsData } from "./linkList";
import Login from "./login";
import Notice from "./notice";
import ResetData from "./resetData";

const Header = (props) => {
  const { model, editFunction, editText, drag, setModel, url, setUrl } = props;
  const [backupModal, setBackupModal] = useState(false);
  const data = {};
  const [data2, setData2] = useState(data);
  const [backupData, setBackupData] = useState("");

  useEffect(() => {
    getLocalStorage();
    setBackupData("");
    // eslint-disable-next-line
  }, [backupModal]);

  //获得本地的数据
  function getLocalStorage() {
    for (var i = 0; i < localStorage.length; i++) {
      data[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
    }
    delete data["password"];
    delete data["userName"];
    setData2(data);
  }

  function saveFile(text) {
    const stringData = text;
    const time = new Date();
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    const day = time.getDate();
    // dada 表示要转换的字符串数据，type 表示要转换的数据格式
    const blob = new Blob([stringData], {
      type: "text/plain;charset=utf-8",
    });
    // 根据 blob生成 url链接
    const objectURL = URL.createObjectURL(blob);
    // 创建一个 a 标签Tag
    const aTag = document.createElement("a");
    // 设置文件的下载地址
    aTag.href = objectURL;
    // 设置保存后的文件名称
    aTag.download = `备份数据${year}-${month}-${day}.txt`;
    // 给 a 标签添加点击事件
    aTag.click();
    // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。
    // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
    URL.revokeObjectURL(objectURL);
  }

  function analysisData(e) {
    const content = e.target.value;
    if (content !== "") {
      try {
        const content2 = JSON.parse(content);
        if (typeof content2 === "number") {
          message.error("数据格式错误，解析失败！");
        }
      } catch (e) {
        message.error("数据格式错误，解析失败！");
      }
    }
  }

  const moreMenu = [
    {
      key: "BackupRecovery",
      label: (
        <>
          {/* eslint-disable-next-line */}
          <a onClick={() => setBackupModal(true)}>
            <InteractionFilled style={{ marginRight: "8px" }} />
            导入/导出数据
          </a>
          <Modal
            title="导入/导出数据"
            open={backupModal}
            onCancel={() => setBackupModal(false)}
            okText="确认"
            cancelText="取消"
            onOk={save}
            destroyOnClose
          >
            <Row style={{ margin: "12px 0px", alignItems: "baseline" }}>
              <Col flex="78px">导出数据：</Col>
              <Col flex="auto">
                <Input value={JSON.stringify(data2)} />
              </Col>
              <Col>
                <Button
                  style={{ width: "80px" }}
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    saveFile(JSON.stringify(data2));
                    message.success("数据导出本地成功，请妥善保存！");
                  }}
                >
                  导出
                </Button>
              </Col>
            </Row>
            <Row style={{ alignItems: "baseline" }}>
              <Col flex="78px">导入数据：</Col>
              <Col flex="auto">
                <Input
                  value={backupData}
                  onChange={(e) => {
                    setBackupData(e.target.value);
                  }}
                  onBlur={(e) => {
                    analysisData(e);
                  }}
                />
              </Col>
              <Col>
                <Upload
                  accept=".txt"
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={(file) => {
                    var reader = new FileReader();
                    reader.readAsText(file);
                    reader.onloadend = function () {
                      setBackupData(reader.result);
                      message.success("导入成功");
                    };
                    return false;
                  }}
                >
                  <Button style={{ width: "80px" }} icon={<UploadOutlined />}>
                    导入
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Modal>
        </>
      ),
    },
    {
      key: "recovery",
      label: <ResetData />,
    },
    {
      key: "contactUs",
      label: (
        <p>
          <MailFilled style={{ marginRight: "8px" }} />
          大数据中心
        </p>
      ),
    },
  ];

  function deepMerge(obj1, obj2) {
    const result = { ...obj2 };
    for (const key in obj1) {
      var value1 = obj1[key];
      var value2 = result[key];
      if (Object.prototype.hasOwnProperty.call(result, key)) {
        if (
          Object.prototype.toString.call(value1) === "[object Object]" &&
          Object.prototype.toString.call(value2) === "[object Object]"
        ) {
          result[key] = deepMerge(value1, value2);
        }
      } else {
        result[key] = value1;
      }
    }
    return result;
  }

  function save() {
    if (backupData !== "") {
      try {
        const data = JSON.parse(backupData);
        if (typeof content2 === "number") {
          message.error("保存失败！");
        } else {
          //根据要恢复的数据，生成对应的localStorage
          const password = window.localStorage.getItem("password");
          const userName = window.localStorage.getItem("userName");
          // window.localStorage.clear();
          if (password || userName) {
            window.localStorage.setItem("userName", userName);
            window.localStorage.setItem("password", password);
          }
          for (var i = 0; i < Object.keys(data).length; i++) {
            var key = Object.keys(data)[i];
            var itemData = window.localStorage.getItem(key);
            console.log(itemData);
            if (key == "funtabs") {
              var lastData = deepMerge(
                JSON.parse(itemData),
                JSON.parse(Object.values(data)[i])
              );
              window.localStorage.setItem(key, JSON.stringify(lastData));
            } else {
              window.localStorage.setItem(key, Object.values(data)[i]);
            }
          }
          message.success("保存成功,即将自动刷新页面～");
          setBackupModal(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      } catch (e) {
        console.log(e);
        message.error("数据格式错误，保存失败！");
      }
    } else {
      message.error("恢复数据未填写，保存失败～");
    }
  }

  return (
    <>
      <div
        className="background"
        style={{
          backgroundImage: `url(${url})`,
        }}
      ></div>
      {/* 用于检测壁纸是否可以正常显示 */}
      <Image
        src={url}
        style={{
          display: "none",
        }}
        onError={() => {
          setUrl(funtabsData.backgroundImage);
          window.localStorage.setItem(
            "backgroundImage",
            funtabsData.backgroundImage
          );
        }}
      />

      <div className="logo">
        <div
          className="logo_img"
          style={{
            backgroundImage: `url(/icons/nyrl.jpg)`,
            backgroundSize: "contain",
            cursor: "pointer",
          }}
        ></div>
      </div>
      {/* 设置按钮 */}
      <div className="settings" id="header">
        <div style={{ display: "none" }}>{/* <Notice /> */}</div>
        <Space style={{ marginRight: "14px" }}>
          <div style={{ display: model }}>
            <Login />
          </div>
          <Button
            type="text"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              display: model,
              marginRight: "-10px",
            }}
            onClick={editFunction}
          >
            {editText}
          </Button>
          <Dropdown
            menu={{
              items: moreMenu,
            }}
            placement="bottom"
            getPopupContainer={() => document.getElementById("header")}
          >
            <Button
              type="text"
              style={{
                marginRight: "10px",
                color: "#ffffff",
                fontWeight: "bold",
                display: model,
              }}
            >
              更多
            </Button>
          </Dropdown>
        </Space>
      </div>
    </>
  );
};

export default Header;
