//建立资源分类
const sourceClass = [
  { value: "tuijian", key: "tuijian", label: "推荐" },
  { value: "sousuo", key: "sousuo", label: "搜索" },
  { value: "gongjv", key: "gongjv", label: "工具" },
  { value: "luntan", key: "luntan", label: "论坛" },
  { value: "daima", key: "biancheng", label: "编程" },
  { value: "keji", key: "keji", label: "科技" },
];

//建立图标资源库
const source = [
  {
    link: "baidu.com",
    sourceClass: "sousuo",
    label: "百度",
    color: "#346efd",
    icon: "baidu.svg",
    describe: "国内最大的搜索引擎",
  },
  {
    link: "tongji.baidu.com",
    sourceClass: "gongjv",
    label: "百度统计",
    color: "#ffffff",
    icon: "tongji.svg",
    describe: "",
  },
  {
    link: "52pojie.cn",
    sourceClass: "luntan",
    label: "吾爱破解",
    color: "#C90E3B",
    icon: "pojie.svg",
    describe: "",
  },
  {
    link: "aliyun.com",
    sourceClass: "keji",
    label: "阿里云",
    color: "#FF6A00",
    icon: "aliyun.svg",
    describe: "",
  },
  {
    link: "oschina.net",
    sourceClass: "biancheng",
    label: "开源中国",
    color: "#239B4B",
    icon: "oschina.svg",
    describe: "",
  },
  {
    link: "segmentfault.com",
    sourceClass: "biancheng",
    label: "思否",
    color: "#019A61",
    icon: "segmentfault.svg",
    describe: "",
  },
  {
    link: "gitlab.com",
    sourceClass: "biancheng",
    label: "GitLab",
    color: "#ffffff",
    icon: "gitlab.svg",
    describe: "",
  },
  {
    link: "juejin.cn",
    sourceClass: "biancheng",
    label: "掘金",
    color: "#2080FF",
    icon: "juejin.svg",
    describe: "",
  },
  {
    link: "wenshu.court.gov.cn",
    sourceClass: "falv",
    label: "中国裁判文书网",
    color: "#ffffff",
    icon: "chineseWenshu.png",
    describe: "",
  },
  {
    link: "itslaw.com",
    sourceClass: "falv",
    label: "无讼案例",
    color: "#2D82F7",
    icon: "wusonganli.png",
    describe: "",
  },
  {
    link: "cn.bing.com",
    sourceClass: "sousuo",
    label: "必应",
    color: "#ffffff",
    icon: "bing.svg",
    describe: "",
  },
  {
    link: "fsoufsou.com",
    sourceClass: "sousuo",
    label: "F搜",
    color: "#ffffff",
    icon: "fsou.svg",
    describe: "",
  },
  {
    link: "github.com",
    sourceClass: "biancheng",
    label: "Github",
    color: "#ffffff",
    icon: "github.svg",
    describe: "",
  },
  {
    link: "ant-design.antgroup.com/index-cn",
    sourceClass: "biancheng",
    label: "Ant design",
    color: "#ffffff",
    icon: "antd.svg",
    describe: "",
  },
  {
    link: "mp.weixin.qq.com",
    sourceClass: "gongjv",
    label: "微信公众平台",
    color: "#17CE22",
    icon: "weixin.svg",
    describe: "",
  },
  {
    link: "csdn.net",
    sourceClass: "biancheng",
    label: "CSDN",
    color: "#FC5734",
    icon: "csdn.svg",
    describe: "",
  },
];

function IconSource(domain) {
  for (let i = 0; i < source.length; i++) {
    if (source[i].link === domain) {
      return source[i];
    }
  }
}

export { IconSource, source, sourceClass };
